import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';  
import * as Icon from 'react-feather';
import Feedback from "../components/Common/Feedback";

import WorksImage1 from "../assets/images/works-image/works-image1.png";
import WorksImage2 from "../assets/images/works-image/works-image2.jpg";
import WorksImage3 from "../assets/images/works-image/works-image3.png";
import WorksImage4 from "../assets/images/works-image/works-image4.jpg";

const ProjectDetails = () => (
    <Layout>
        <SEO title="XPRIZE Mask" /> 

        <Navbar />

        <PageBanner pageTitle="Open Sourced Next-Gen Mask for the Public Good" />

        <div className="project-details-area ptb-80">
            <div className="container">
                <div className="row">
            

                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="project-details-image">
                            <img src={WorksImage3} alt="work" /> 
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="project-details-image">
                            <img src={WorksImage1} alt="work" /> 
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="project-details-image">
                    <iframe width="100%" height="600" src="https://www.youtube.com/embed/Cd6n9Y7K4tk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>

                    <div className="col-lg-12 col-md-12">
                        <div className="project-details-desc">
                            <h3>International XPRIZE Winning Mask Design</h3>
                            
                            <p>In 2020, a team made up of Faraya consultants competed in the International XPRIZE challenge to design a Next-Generation Surgical Mask. The team’s design won first place out of 1000 entries submitted worldwide.</p>

                            <p>Faraya has open sourced a version of this mask design, to allow people around the globe to produce and leverage these masks for their day-to-day use. The mask is designed to increase breathability, and reduces heat within the mask, to prevent glasses from fogging during use, and improve the overall experience of wearing a fast.</p>

                            <div className="project-details-information">
                                <div style={{fontSize: 12}} className="single-info-box">
                                    <h4>Team Members</h4>
                                    <p>Katie Pascavis</p>
                                    <p>John Patterson</p>
                                    <p>Josh Chang</p>
                                    <p>Tarun Suresh</p>
                                    <p>Nik Dave</p>
                                </div>


                                <div style={{paddingRight: 40}}  className="single-info-box">

                            </div>

                                <div style={{paddingRight: 40}} className="single-info-box">
                                    <h4>Disclaimer</h4>
                                    <p style={{fontSize: 12}}>This mask is not a medical device and is not guaranteed to meet any specific performance criteria. The designs disclosed in this instruction guide are for informational purposes only and come with absolutely no warranty.</p>
                                </div>


                                <div className="single-info-box">
                              
                                </div>

                                <div className="single-info-box">
                                <a href="https://docs.google.com/document/d/12DYaZ--4OZdMC3uKS_wFHIOBUA-QPm1g/edit?usp=sharing&ouid=112827086685831558701&rtpof=true&sd=true" className="btn btn-primary" target="_blank" rel="noreferrer"> Instructions</a>

                                <a style={{marginTop: 30}} href="https://docs.google.com/document/d/1AKrmSrfKov5q4Gcg2Vr7HNNk5znPO1rl/edit?usp=sharing&ouid=112827086685831558701&rtpof=true&sd=true" className="btn btn-primary" target="_blank" rel="noreferrer"> Template File</a>

                                <a style={{marginTop: 30}} href="https://docs.google.com/document/d/1iINO9cmayZG8IiWJwePzsm1C59YEkJTK2wsb35kX94o/edit?usp=sharing" className="btn btn-primary" target="_blank" rel="noreferrer"> License</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <Feedback/>
        <Footer />
    </Layout>
)

export default ProjectDetails;